import React from 'react';
import Images from './Images';
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
export const SocialIcons=(props)=>{
	return(
    <ul className={`sociallists ${props.override&&'removetransform'}`}>
    {props.type&&props.type==2&&
    <li className="whatsapp"><a href="#" ><i className='fab fa-whatsapp'></i></a></li>
    }

    <li className="facebook"><a href="https://www.facebook.com/londonupperlimbsurgery.co.uk" target="_blank" ><i className="fab fa-facebook-f"></i></a></li>
    {!props.type&&
    <li className="youtube"><a href="https://www.youtube.com/channel/UCalHcQCZPjR06--XE-h9z9Q" target="_blank" ><i className="fab fa-youtube"></i></a></li>
	}
    <li className="twitter"><a href="https://twitter.com/karthikkarup" target="_blank" ><i className="fab fa-twitter"></i></a></li>
    <li className="insta"><a href="https://www.instagram.com/upperlimbsurgeon/" target="_blank" ><i className="fab fa-instagram"></i></a></li>
    <li className="in"><a href="https://uk.linkedin.com/in/karthik-karuppaiah-2255bb43" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
    
    </ul>
		)
}
export const ShareIcons=(props)=>{
  return(
    <ul className={`sociallists ${props.override&&'removetransform'}`}>
    <li className="facebook"><FacebookShareButton url={props.link}><a href="#" ><i className="fab fa-facebook-f"></i></a></FacebookShareButton></li>
    <li className="whatsapp"><WhatsappShareButton url={props.link}><a href="#" ><i className='fab fa-whatsapp'></i></a></WhatsappShareButton></li>
    <li className="twitter"><TwitterShareButton url={props.link}><a href="#" ><i className="fab fa-twitter"></i></a></TwitterShareButton></li>
    <li className="in"><LinkedinShareButton url={props.link}><a href="#"><i className="fab fa-linkedin-in"></i></a></LinkedinShareButton></li>
    
    </ul>
    )
}

export const InformationBox=(props)=>{
	return(
 <div className={`infobox ${props.toggleform&&'hidebox'}`}>
	 <a href="tel:+44 (0)20 8137 0257"><div className={`iconbox ${props.override&&'removejustify'}`}><Images.smartphone/><span>+44 (0)20 8137 0257</span></div></a>
	 <a href="mailto:karthik.medsec@gmail.com"><div className={`iconbox ${props.override&&'removejustify'}`}><Images.mail/><span>karthik.medsec@gmail.com</span></div></a>  
  </div>
  )
}